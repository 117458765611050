import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX, SERVICES } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  FE_URL: 'https://tmtrav-car-admin-dev.tma-swerp.com/',
  FE_URL_SALE: `https://tmtrav-ecommerce-${PREFIX.DEV}.tma-swerp.com/`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  IFRAME_CONNECT_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com/app-connect`,
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  SERVICES: SERVICES,
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'TMTravel-white',
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  SHOP_ADMIN_HUB_URL: 'https://tmtrav-shop-admin-dev.tma-swerp.com',
  VOUCHER_ADMIN_HUB_URL: 'https://tmtrav-voucher-hub-dev.tma-swerp.com',
  BANNER_HUB_URL: 'https://tmtrav-banner-dev.tma-swerp.com',
  SOCCHAT_VISIBLE: true,
  CHAT_MODULE: 'CAR',
  baseUrl: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com`,
  CHAT_FAQ_PORTAL_URL: 'https://chat-faq-admin.tma-swerp.com',
  USER_PROFILE: 'user-profile',
  CAR_RENTAL_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com',
};
