import { NavigationItem } from '../models/interfaces/navigation';
import { SoctripIcons } from './soctrip-icon.enum';
import { AppRoutes } from '../enum/routes.enum';
import { environment } from 'src/environments/environment';

export const MenuSidebar: NavigationItem[] = [
  {
    path: 'dashboard',
    title: 'dashboard',
    icon: SoctripIcons.BAR_CHART_SQUARE_01,
    visible: true
  },
  {
    path: 'agency-management',
    title: 'agency-management',
    icon: SoctripIcons.USER_CHECK_01,
    visible: true
  },
  {
    path: 'booking-helpdesk',
    title: 'booking-helpdesk',
    icon: SoctripIcons.FILE_SEARCH_02,
    visible: true
  },
  {
    path: `${AppRoutes.REFUND_MANAGEMENT}`,
    title: 'refund-management',
    icon: SoctripIcons.CREDIT_CARD_REFRESH,
    visible: true
  },
  {
    path: `${AppRoutes.VOUCHER_MANAGEMENT}`,
    title: 'voucher-management',
    icon: SoctripIcons.TICKET_01,
    visible: true
  },
  {
    path: `${AppRoutes.AUTOMAKER_MANAGEMENT}`,
    title: 'automaker-management',
    icon: SoctripIcons.CAR_01,
    visible: true
  },
  {
    path: `${AppRoutes.AMENITY_MANAGEMENT}`,
    title: 'amenity-management',
    icon: SoctripIcons.TOOL_02,
    visible: true
  },
  {
    path: AppRoutes.BANNER_MANAGERMENT,
    title: AppRoutes.BANNER_MANAGERMENT,
    icon: SoctripIcons.IMAGE_INDENT_LEFT,
    visible: true
  },
  {
    path: AppRoutes.CHAT_FAQ_ASSISTANT,
    title: AppRoutes.CHAT_FAQ_ASSISTANT,
    icon: SoctripIcons.MESSAGE_QUESTION_SQUARE,
    visible: environment.SOCCHAT_VISIBLE
  },
];
