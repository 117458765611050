export enum AppRoutes {
  REFUND_MANAGEMENT = 'refund-management',
  REFUND_HUB = 'refund-hub',
  CAR_RENTAL = 'car_rental',
  AUTOMAKER_MANAGEMENT = 'automaker-management',
  AMENITY_MANAGEMENT = 'amenity-management',
  VOUCHER_MODULE = 'voucher-module',
  VOUCHER_MANAGEMENT = 'voucher-management',
  BANNER_MANAGERMENT = 'banner-management',
  ADMIN_SUPPORT = 'admin-support',
  CHAT_FAQ_MODULE = 'chat-faq-module',
  CHAT_FAQ_ASSISTANT = 'faq-assistant',
}
