import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetApiService {
  chatApi: string = `${environment.baseUrl}/chat`;

  constructor(
    private httpClient: HttpClient,
  ) { }

  createHeader() {
    const header = new HttpHeaders({
      'content-type': 'application/json',
    });
    return header;
  }

  getAdminChatSessionsCount(status: string, module: string) {
    const options = { headers: this.createHeader() };
    const chatGeneralApi = `${this.chatApi}/sessions/support/status/${status}/count?module=${module}`;
    return this.httpClient.get(chatGeneralApi, options);
  }
}
