<div
  *ngIf="stateView === 'APP'"
  class="w-full mx-auto flex flex-col bg-gray-50"
>
  <!-- Virtual header has the same height with <header>, to support UI flows -->
  <header
    class="min-h-[3.125rem] max-h-[3.125rem] w-full bg-transparent border-transparent select-none"
  ></header>
  <!-- Virtual header -->

  <!-- HEADER, with proper z-index for another elements to pop up: dialog, tooltip, ... -->
  <app-header
    class="fixed z-20 h-[3.125rem] min-w-[480px] bg-white shadow-sm w-full"
    [isSmallScreen]="isSmallScreen"
    [user]="user"
    (openSidebar)="sidebarVisible = $event"
  ></app-header>

  <!-- Body -->
  <div class="w-screen h-auto md:min-w-[480px] flex flex-grow mx-auto relative">
    <!-- SIDEBAR -->
    <app-sidebar [(sidebarVisible)]="sidebarVisible"></app-sidebar>

    <!-- CONTENT -->
    <div class="flex-1 h-full p-6 overflow-clip">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
