import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/layouts/admin-layout/admin-layout.component';
import { AppRoutes } from './core/enum/routes.enum';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'booking-helpdesk',
        loadChildren: () =>
          import(
            './features/booking-management/booking-management.module'
          ).then((m) => m.BookingManagementModule),
      },
      {
        path: 'agency-management',
        loadChildren: () =>
          import('./features/agency-management/agency-management.module').then(
            (m) => m.AgencyManagementModule,
          ),
      },
      {
        path: `${AppRoutes.REFUND_MANAGEMENT}`,
        loadChildren: () =>
          import('./features/refund-management/refund-management.module').then(
            (m) => m.RefundManagementModule,
          ),
      },
      {
        path: `${AppRoutes.VOUCHER_MANAGEMENT}`,
        loadChildren: () =>
          import(
            './features/voucher-management/voucher-management.module'
          ).then((m) => m.VoucherManagementModule),
      },
      {
        path: `${AppRoutes.AUTOMAKER_MANAGEMENT}`,
        loadChildren: () =>
          import(
            './features/automaker-management/automaker-management.module'
          ).then((m) => m.AutomakerManagementModule),
      },
      {
        path: `${AppRoutes.AMENITY_MANAGEMENT}`,
        loadChildren: () =>
          import(
            './features/amenity-management/amenity-management.module'
          ).then((m) => m.AmenityManagementModule),
      },
      {
        path: `${AppRoutes.BANNER_MANAGERMENT}`,
        loadChildren: () =>
          import('./features/banner-management/banner-management.module').then(
            (m) => m.BannerManagementModule,
          ),
      },
      {
        path: AppRoutes.ADMIN_SUPPORT,
        loadChildren: () =>
          import('./features/admin-support/admin-support.module').then(
            (m) => m.AdminSupportModule,
          ),
      },
      {
        path: AppRoutes.CHAT_FAQ_ASSISTANT,
        loadChildren: () =>
          import(
            './features/chat-faq-assistant/chat-faq-assistant.module'
          ).then((module) => module.ChatFaqAssistantModule),
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
